<template>
  <div>
    <!-- Navigation -->
    <navigation-bar></navigation-bar>

    <!-- Hauptinhalt -->
    <div class="container mt-4">
      <!-- Linker Bereich: Auftrag Details -->
      <div class="row">
        <div class="col-md-6 order-details text-left">
          <h4>Megrendelés adatok</h4>
          <ul v-if="megrendeles">
            <li><span>Megrendelő:</span> <strong>{{ megrendeles.megrendelo }}</strong></li>
            <li><span>Felrakó:</span> <strong>{{ megrendeles.felrako }}</strong></li>
            <li><span>Felrakó hely:</span> <strong>{{ megrendeles.felhely }}</strong></li>

            <li><span>Lerakó:</span> <strong>{{ megrendeles.lerako }}</strong></li>
            <li><span>Lerakó hely:</span> <strong>{{ megrendeles.lehely }}</strong></li>
            <li><span>Cikk:</span> <strong>{{ megrendeles.cikk }}</strong></li>
            
            <li><span>Dátum:</span> <strong>{{ megrendeles.m_datumtol }}</strong></li>
            <li><span>Szállítási határidő:</span> <strong>{{ megrendeles.szall_hatarido }}</strong></li>

            
            <li><span>Súly:</span> <strong>{{ megrendeles.m_suly }}</strong></li>
            <li><span>KM:</span> <strong>{{ megrendeles.m_km }}</strong></li>
            
            <li><span>Hivatkozási szám:</span> <strong>{{ megrendeles.hivatkozasi_szam }}</strong></li>
            <li>
              <span>GMP: </span>
              <input type="checkbox" disabled v-model="gmpCheckbox">
              <span v-if="gmpCheckbox"> igen</span>
              <span v-else> nem</span>
            </li>

              
            <li><span>Megrendelői ár:</span> <strong>{{ megrendeles.m_aregysegar }}</strong></li>
            <li><span>Ártípus:</span> <strong>{{ megrendeles.m_egysegarme }}</strong></li>

            <li><span>Alapért.alv díj:</span> <strong>{{ megrendeles.mar_egysegar }}</strong></li>
            <li><span>Alv ártipus:</span> <strong>{{ megrendeles.mar_egysegarme }}</strong></li>
            
            <li><span>Megjegyzés:</span> <strong>{{ megrendeles.megjegyzes }}</strong></li>
            
            <li><span>Fuvarszervező:</span> <strong>{{ megrendeles.fuvarszervezo }}</strong></li>
            <li><span>Készítette:</span> <strong>{{ megrendeles.felh }}  ({{megrendeles.made  }})</strong></li>
            <li><span>Utolsó módosítás:</span> <strong>{{ megrendeles.mod_felh }}  ({{ megrendeles.timest }})</strong></li>
            <li><span>Csoport:</span> <strong>{{ megrendeles.csoport }}</strong></li>
          </ul>
          <p v-else>Adattöltés folyamatban...</p> <!-- Anzeige eines Ladezustands -->
        </div>

        <!-- Rechter Bereich: Buttons für Aktionen -->
        <div class="col-md-4 action-buttons">


          <button class="btn btn-primary btn-block mb-2" @click="editOrder">Megrendelés szerkesztése</button>

             <!-- Megrendelés kész Button (Deaktiviert) -->
              <button 
                class="btn btn-success btn-block mb-2" 
                @click="markAsComplete" 
                :disabled="true"
              >
              Megrendelés kész
            </button>


            <button class="btn btn-info btn-block mb-2" @click="toggleDateField">Dátum módosítása</button>
            <button class="btn btn-warning btn-block mb-2" @click="toggleCopyField">Megrendelés másolása</button>

            <!-- Megrendelés törlése Button () -->
            <button 
              class="btn btn-danger btn-block mb-2" 
              @click="confirmDeleteOrder" >
              Megrendelés törlése
            </button>





            <!-- Eingabefeld für Datum -->
            <div v-if="showDateField" class="mt-3">
              <label for="newDate">Új dátum:</label>
              <input 
                id="newDate" 
                type="date" 
                v-model="newDate" 
                class="form-control mb-2" 
              />
              <button class="btn btn-primary btn-block" @click="startDateUpdate">Start</button>
            </div>

            <!-- Eingabefeld für Megrendelés másolása -->
            <div v-if="showCopyField" class="mt-3">
              <label for="copyDate">Új dátum:</label>
              <input 
                id="copyDate" 
                type="date" 
                v-model="copyDate" 
                class="form-control mb-2" 
              />
              <label for="copyWeight">Új mennyiség:</label>
            <input 
              id="copyWeight" 
              type="number" 
              v-model="m_suly_new" 
              class="form-control mb-2" 
            />


              <button class="btn btn-primary btn-block" @click="startCopyOrder">Start</button>
            </div>



          </div>

      
      
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/axiosInstance';
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      megrendeles: null,
      showDateField: false, // Steuerung für Datumfeld
      showCopyField: false, // Für Megrendelés másolása
      newDate: '',          // Neues Datum
      copyDate: '',          // Datum für Megrendelés másolása
      m_suly_new: 0 // Neues Feld für das Gewicht
    };
  },
  computed: {
    ...mapGetters(['getorderData']),
    az() {
      return this.getorderData?.az || null;
    },
    napifo_az() {
      return this.getorderData?.napifo_az || null;
    },
    gmpCheckbox() {
      console.log('gmp',this.megrendeles.gmp);
    return this.megrendeles?.gmp == -1;  // true für Ja (-1), false für Nein (0)
  }  
  },
  mounted() {
    this.fetchOrderDetails();
    this.updateLogParams();
  },
  methods: {
    ...mapMutations(['setLogParams']),
    updateLogParams() {
    const logParams = {
      user: '',  // oder fsz, je nach Logik
      table_name: 'ftr_megrendeles',
      record_id: this.napifo_az
    };
    this.$store.commit('setLogParams', logParams);
  },
  editOrder() {
    this.$router.push({ name: 'editOrder' });
  },
  toggleDateField() {//****************************************************************** */
      // Zeige oder verstecke das Datumsfeld
      this.showDateField = !this.showDateField;
    },
      // Umschalten des Datumsfelds für Megrendelés másolása
    toggleCopyField() {//***************************************************************** */
      this.showCopyField = !this.showCopyField;
      this.m_suly_new = this.megrendeles?.m_suly || 0; // Fülle das Feld mit dem aktuellen Gewicht
      this.showDateField = false; // Schließt das andere Feld
    },
    async startDateUpdate() {//****  visznylat áthelyezése másik napra ***************************************** */
      if (!this.newDate) {
        alert('Bitte ein gültiges Datum eingeben!');
        return;
      }

      // Placeholder für API-Aufruf

      const datawert={
        m_datumtol:this.newDate
      };

      const postData = {
        az: this.napifo_az,
        data: datawert
      };

      try {

        console.log('athelyezes',postData);
        const response = await axiosInstance.post('/megrendeles/api_update_megrendeles.php', postData);

        if (response.status === 200 && response.data.success) {
          //alert('Megrendelés áthelyezése kész.');
          //this.fetchOrderDetails(); // Daten erneut laden
          //this.showDateField = false; // Feld ausblenden

          this.$store.commit('setNapiDatum', this.newDate);
          // Leite zur Seite "osszesMegrendeles" weiter
          this.$router.push({ name: 'osszesMegrendeles'});

        } else {
          alert('Fehler beim Aktualisieren des Datums.');
        }
      } catch (error) {
        console.error('Fehler beim Aktualisieren des Datums:', error);
        alert('Hiba történt beim Aktualisieren.');
      }
    },

    async startCopyOrder() {//****************************************************************************** */
      if (!this.copyDate) {
        alert("Kérjük, adjon meg egy dátumot a másoláshoz!");
        return;
      }

      const postData = {
        az: this.napifo_az,
        datum_new: this.copyDate,
        m_suly_new: this.m_suly_new // Übergebe das neue Gewicht
      };

      try {
        const response = await axiosInstance.post("/megrendeles/copy_megrendeles.php", postData);
        if (response.data.success) {
         // alert("Megrendelés másolása sikeres! Új az: " + response.data.id);
          this.$store.commit('setNapiDatum', this.copyDate);
          // Leite zur Seite "osszesMegrendeles" weiter
          this.$router.push({ name: 'osszesMegrendeles' });

        } else {
          console.error("Hiba történt a másolás során:", response.data.error);
          alert("Hiba történt a másolás során: " + response.data.error);
        }
      } catch (error) {
        console.error("API-hiba:", error);
        alert("Hiba történt a másolás során. Kérjük, próbálja újra.");
      }
    },

    async fetchOrderDetails() {//******************************************************************************** */
      const postData = {
        az: this.napifo_az
      };
      try {
        console.log('posData',postData);
        const url = "/megrendeles/egy_megrendeles.php";
        const response = await axiosInstance.post(url, postData);

        console.log(response.data);

        if (response.status === 200 && response.data.data && response.data.data.length > 0) {
          this.megrendeles = response.data.data[0];
          console.log('megrendeles', this.megrendeles);
        } else {
          console.log('Antwort enthält keine Daten oder ist nicht 200:', response);
          this.errorMessage = 'Fehler beim Abrufen der Daten: ' + response.statusText;
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
        this.errorMessage = 'Fehler beim Abrufen der Daten: ' + error.message;
      }
    },
    markAsComplete() {
      // Logik für das Markieren des Auftrags als abgeschlossen
    },
    modifyQuantity() {
      // Logik für das Ändern der Menge
    },
    modifyDate() {
      // Logik für das Ändern des Datums
    },
    confirmDeleteOrder() {
      if (confirm('Biztosan törölni szeretné ezt a megrendelést?')) {
          this.deleteOrder();
      }
    },
    async deleteOrder() {//****************************************************************************** */
      // Logik für das Löschen des Auftrags
      
      const datawert={
        storno:-1
      };

      const postData = {
        az: this.napifo_az,
        data: datawert
      };

      try {

          console.log('storno',postData);
        const response = await axiosInstance.post('/megrendeles/api_update_megrendeles.php', postData);

        if (response.status === 200 && response.data.success) {
          //alert('Törlés kész ');
          this.$router.push({ name: 'osszesMegrendeles' });
        } else {
          alert('Fehler beim Aktualisieren des Datums.');
        }
      } catch (error) {
        console.error('Fehler beim Aktualisieren des Datums:', error);
        alert('Hiba történt beim Aktualisieren.');
      }


    }
  }
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}

.order-details ul {
  list-style-type: none;
  padding: 0;
}

.order-details ul li {
  margin-bottom: 10px;
}

.action-buttons .btn {
  width: 80%; /* Breite der Buttons auf 80% reduzieren */
  padding: 10px;
  font-size: 16px;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Buttons nicht so breit machen */
}

input[type="checkbox"][disabled] {
  opacity: 0.8; /* Weniger durchsichtig machen */
  border: 1px solid #ccc; /* Grauen Rahmen hinzufügen */
}

#newDate {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  margin-bottom: 10px;
}

</style>
