<template>

<navigation-bar></navigation-bar>

<div class="container mt-4">
    <h2 class="text-center">Jelszó Módosítása</h2>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <form @submit.prevent="changePassword">
          <div class="form-group">
            <label for="newPassword">Új jelszó</label>
            <input type="password" v-model="newPassword" id="newPassword" class="form-control" required />
          </div>
          <div class="form-group">
            <label for="confirmPassword">Új jelszó megerősítése</label>
            <input type="password" v-model="confirmPassword" id="confirmPassword" class="form-control" required />
          </div>
          <button type="submit" class="btn btn-primary btn-block mt-3">Jelszó Módosítása</button>
        </form>
        <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
        <div v-if="successMessage" class="alert alert-success mt-3">{{ successMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/axiosInstance';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      errorMessage: '',
      successMessage: '',
      isChangingPassword: false,
    };
  },
  computed: {
    ...mapState({
      szemAz: state => state.userData.szem_az,
    })
  },
  methods: {
    async changePassword() {
      this.errorMessage = '';
      this.successMessage = '';

      if (this.newPassword !== this.confirmPassword) {
        this.errorMessage = 'A beírt ellenőrző jelszó hibás!';
        return;
      }

      this.isChangingPassword = true;

      try {
        const response = await axiosInstance.post('/ftr/change_password.php', {
          szem_az: this.szemAz,
          newPassword: this.newPassword
        });

        if (response.data.status === 'success') {
          this.successMessage = 'Jelszó sikeresen módosítva.';
          this.newPassword = '';
          this.confirmPassword = '';
          this.$router.push({ name: 'menu' });
        } else {
          this.errorMessage = 'Hiba a jelszó módosításnál: ' + response.data.message;
        }
      } catch (error) {
        this.errorMessage = 'Hiba történt: ' + error.message;
      } finally {
        this.isChangingPassword = false;
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 500px;
}
.form-group {
  margin-bottom: 15px;
}
</style>
