<template>
  <div>
    <navigation-bar></navigation-bar>
    <div class="container-fluid">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <button class="btn btn-primary" @click="previousDay">Előző nap</button>
        <h3>{{ formattedDateWithDay  }}</h3>
        <button class="btn btn-primary" @click="nextDay">Következő nap</button>
      </div>
      <div class="d-flex justify-content-center mb-3">
        <button
          class="btn mx-1"
          :class="{ 'btn-primary': isSelectedDate(date), 'btn-secondary': !isSelectedDate(date) }"
          v-for="date in dateButtons"
          :key="date"
          @click="selectDate(date)"
        >
          {{ formatDate(date) }}
        </button>
      </div>
      
      <div class="row flex-nowrap" style="overflow-x: auto;">
        <div class="col-2" v-for="(fuvarszervezo, index) in fuvarszervezok" :key="index" :style="{ minWidth: this.tableWidth + 'px' }">
          <div class="fuvarszervezo-name text-center mb-3" :style="{ backgroundColor: fuvarszervezo.color }">
            {{ fuvarszervezo.name }}
          </div>
          <div class="table-responsive" :style="{ maxHeight: tableHeight + 'px', overflowY: 'scroll' }">
            <table class="table table-bordered">
             
              <tbody>
                <tr 
                  v-for="(row, rowIndex) in fuvarszervezo.data" 
                  :key="rowIndex"
                  @mouseover="showDetails(row, $event)" 
                  @mouseleave="clearDetails"
                 
                >
                  <td
                    :style="{ width: this.columnWidth + 'px', 
                      backgroundColor: getBackgroundColor(row.tipus1),
                      color: getFontColor(row.tipus1) 
                    }"

                    @click="handleCellClick(row, 1)"
                    @contextmenu.prevent="showContextMenu(row, $event, 1)"   
                    @mouseover="showAdditionalTooltip(row, $event, 1)"
                    @mouseleave="clearAdditionalTooltip"
                  >
                    {{ row.szoveg1 }}
                  </td>
                  <td
                    :style="{ width: this.columnWidth + 'px', 
                      backgroundColor: getBackgroundColor(row.tipus2),
                      color: getFontColor(row.tipus2) 
                    }"

                    @click="handleCellClick(row, 2)"
                    @contextmenu.prevent="showContextMenu(row, $event, 2)"
                    @mouseover="showAdditionalTooltip(row, $event, 2)"
                    @mouseleave="clearAdditionalTooltip"
                  >
                    {{ row.szoveg2 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

   <!-- Kontextmenü -->
   <div v-if="showMenu" class="context-menu" :style="{ top: menuY + 'px', left: menuX + 'px' }">
        <ul>
          <li @click="updateTipus(selectedRow, selectedSide)">Magasabban priorizált rakodás</li>
          <li @click="updateTipus(selectedRow, selectedSide)">Magasabban priorizált rakodás megszüntetése</li> <!-- Neue Option -->
          <li @click="addNewRow(selectedRow.napifo_az, fsz)">Új fuvar hozzáadása </li> <!-- Neue Option -->
          <li @click="deleteRow(selectedRow)">Fuvar sor törlése</li> <!-- Löschoption -->
          <li @click="updateTipusTo62(selectedRow, selectedSide)">Adott rendszám leadva</li> <!-- Neue Option -->
          <li @click="updateTipusTo63(selectedRow, selectedSide)">Adott rendszám törlése</li> <!-- Neue Option -->
          <li @click="showDatePicker">Áthelyezés másik napra</li> <!-- Neue Option -->
          <li @click="closeContextMenu">Kilép</li>
        </ul>
      </div>

      <!-- Tooltip mit zusätzlichen Informationen -->
      <div 
        v-if="tooltipData" 
        class="tooltip" 
        :style="{ top: tooltipY + 'px', left: tooltipX + 'px' }"
      >
        <p><strong>Viszonylat:</strong> {{ tooltipData.felrako }} - {{ tooltipData.lerako }}</p>
        <p><strong>Megrendelő:</strong> {{ tooltipData.megrendelo }}</p>
        <p><strong>Cikk:</strong> {{ tooltipData.cikk }}</p>
        <p><strong>Szállítási határidő:</strong> {{ tooltipData.szall_hatarido }}</p>
        <p><strong>Hivatkozási szám:</strong> {{ tooltipData.hivatkozasi_szam }}</p>
        <p><strong>Megr. ár:</strong> {{ tooltipData.megr_ar }} {{ tooltipData.megrar_me }}</p>
        <p><strong>Alv. ár:</strong> {{ tooltipData.alv_ar }} {{ tooltipData.alvar_me }}</p>
        <p><strong>Megjegyzés:</strong> {{ tooltipData.megjegyzes }}</p>
      </div>

      <!-- Zweite Tooltip mit spezifischen Informationen für tipus 6, 61, 7, 71 -->
      <div 
        v-if="additionalTooltipData" 
        class="tooltip" 
        :style="{ top: additionalTooltipY + 'px', left: additionalTooltipX + 'px' }"
      >
        <p><strong>Fuvarozó:</strong> {{ additionalTooltipData.fuvarozo }}</p>
        <p><strong>Kisár:</strong> {{ additionalTooltipData.kisar }}</p>
      </div>



     

    </div>



<!-- Hier ist der Platz für das Datums-Picker-Modal -->
<div class="modal">
  <div class="modal-content">
    <h4>Test Modal</h4>
    <input type="date" />
    <button>Bestätigen</button>
    <button>Abbrechen</button>
  </div>
</div>


  </div>






</template>

<script>
import axiosInstance from '@/axiosInstance';
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex'; // Dies ist der richtige Platz für den Import
//import { UserTypes } from '@/store/index.js'; // Pfad zum Store anpassen, falls erforderlich

export default {
  data() {
    return {
      currentDate: new Date(),
      fixedDates: [],
      fuvarszervezok: [], // Array für die Fuvarszervezo-Namen und Farben sowie deren Daten
      tableWidth: 200, // Parameter für die Breite der Tabellen
      tableHeight: 700, // Parameter für die Höhe der Tabellen
      columnWidth: 70, // Parameter für die Breite der Spalten
      currentCegaz: 1, // Setze einen festen Wert oder passe diesen an deine Umgebung an
      valasz: {}, // Zur Speicherung der Antwort
      tooltipData: null,    // Daten, die im Tooltip angezeigt werden
      tooltipX: 0,
      tooltipY: 0,
      showMenu: false,   // jobb egér
      menuX: 0,
      menuY: 0,
      selectedRow: null, // Speichert die ausgewählte Zeile
      selectedSide: null, // Speichert, ob die linke oder rechte Zelle geklickt wurde
      showDateModal: false, // Um das Datums-Picker-Modal anzuzeigen
      newDate: null, // Das ausgewählte neue Datum
      additionalTooltipData: null, // Daten für die zweite Tooltip
      additionalTooltipX: 0,
      additionalTooltipY: 0
    };
  },
  computed: {
    ...mapGetters(['getUserData', 'isFuvarszervezo']), // Füge den Getter für den Benutzertyp hinzu
    ...mapGetters(['getUserData']),
    ...mapGetters(['getNapiDatum']),
    fsz() {
      return this.getUserData ? this.getUserData.fsz : null;
    },
    ceg_az() {
      return this.getUserData ? this.getUserData.ceg_az : null;
    },
    formattedDate() {
    if (this.getNapiDatum) {
      return this.getNapiDatum; // Wenn napi_datum im Store gesetzt ist, verwende diesen Wert
    }
    // Fallback: aktuelles Datum, falls napi_datum noch nicht gesetzt ist
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Monate beginnen bei 0
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  },
    formattedNapiDatum() {
        return this.getNapiDatum || "Kein Datum gesetzt"; // Fallback, falls das Datum nicht gesetzt ist
    },
    currentDatum() {
      return this.getNapiDatum; // Alias für Klarheit
    },
    formattedDateWithDay() {
    const daysOfWeek = ["vasárnap", "hétfő", "kedd", "szerda", "csütörtök", "péntek", "szombat"];
    const napiDatum = this.getNapiDatum ? new Date(this.getNapiDatum) : new Date();
    const dayName = daysOfWeek[napiDatum.getDay()];
    const year = napiDatum.getFullYear();
    const month = String(napiDatum.getMonth() + 1).padStart(2, '0'); // Monate beginnen bei 0
    const day = String(napiDatum.getDate()).padStart(2, '0');
    return `${year}.${month}.${day} (${dayName})`;
  },
    dateButtons() {
      const baseDate = this.getNapiDatum ? new Date(this.getNapiDatum) : new Date();
    const dates = [];
    for (let i = -2; i <= 2; i++) {
      const newDate = new Date(baseDate);
      newDate.setDate(baseDate.getDate() + i);
      dates.push(newDate);
    }
    return dates;
    }
  },
  methods: {
    ...mapMutations(['setorderData']), // Nutze mapMutations, um die Mutation in die Komponente zu integrieren
    ...mapMutations(['setLogParams']),
    
    
    showAdditionalTooltip(row, event, side) {//*********************************************************** */
      if ([6, 61, 7, 71].includes(parseInt(side == 1 ? row.tipus1 : row.tipus2))) {
        this.additionalTooltipData = {
          fuvarozo: side == 1 ? row.fuvarozo1 : row.fuvarozo2,
          kisar: side == 1 ? row.kisar1 : row.kisar2
        };
        this.additionalTooltipX = event.clientX + 10;
        this.additionalTooltipY = event.clientY + 10;
      }
    },



    clearAdditionalTooltip() {//************************************************************************** */
      this.additionalTooltipData = null;
    },

    updateDateInStore() {//***************************************************************************** */
      // Diese Methode aktualisiert das Datum im Vuex Store
      this.$store.commit('setNapiDatum', this.formattedDate);
      console.log('setNapiDatum', this.formattedDate);
    },

    updateLogParams() {//********************************************************************************* */
      const logParams = {
        user: this.fsz,  // oder fsz, je nach Logik
        table_name: '',
        record_id: 0
      };
      this.$store.commit('setLogParams', logParams);
    },
    showDatePicker() {//**************************************************************************** */
  // Überprüfe, ob tipus1 oder tipus2 == 6 oder 61 ist, bevor das Modal angezeigt wird

    // Überprüfe, ob tipus1 oder tipus2 == 6 oder 61 ist, bevor das Modal angezeigt wird
//    ////console.log('Modal wird angezeigt++++++++:', this.showDateModal);
//    ////console.log('Selected Side:', this.selectedSide);
  
//    ////console.log('Selected Row:', this.selectedRow);


  if (
    (this.selectedSide === 1 && (parseInt(this.selectedRow.tipus1) === 6 || parseInt(this.selectedRow.tipus1) === 61)) ||
    (this.selectedSide === 2 && (parseInt(this.selectedRow.tipus2) === 6 || parseInt(this.selectedRow.tipus2) === 61))
  ) {
    this.showDateModal = true;
  //  ////console.log('true--------------');
  } else {
    alert('Csak nem lejelentett rendszámok helyezhetők át!');
  }
},

  closeDatePicker() {//*********************************************************************************** */
    this.showDateModal = false;
  },

  /*
  handleDateChange(newDate) {
      // Angenommen, newDate ist das Datum, das aktualisiert oder festgelegt wurde
      this.$store.commit('setNapiDatum', newDate);
    },
*/
  async moveKennzeichen() {//***************************************************************************** */
    if (!this.newDate) {
      alert('Kérem válasszon ki egy érvényes dátumot');
      return;
    }

    try {
      // API-Aufruf zur Verschiebung des Kennzeichens in der Datenbank
      const postData = {
        az: this.selectedRow.az,
        tipusField: this.selectedSide === 1 ? 'tipus1' : 'tipus2',
        newDate: this.newDate, // Neues Datum, auf das verschoben werden soll
      };

      const response = await axiosInstance.post('/drive/move_kennzeichen.php', postData);

      if (response.status === 200 && response.data.success) {
        this.fetchData(); // Aktualisiere die Daten
        this.showDateModal = false; // Schließe das Modal
        alert('Kennzeichen erfolgreich verschoben.');
      } else {
        alert('Fehler beim Verschieben des Kennzeichens.');
      }
    } catch (error) {
      ////console.error('Fehler beim API-Aufruf:', error);
      alert('Fehler beim API-Aufruf.');
    }
  },
    async addNewRow(napifo_az, fsz) {//********************************************************************************************* */
        try {
          const postData = {
            napifo_az: napifo_az, // Übergebe den napifo_az-Wert
            felh: fsz             // Übergebe den angemeldeten Fuvarszervező
          };

    //    ////console.log('new_row',postData);

          const response = await axiosInstance.post('/drive/new_row.php', postData); // Passe den API-Endpunkt an

          if (response.status === 200) {
         //   alert('Új sor sikeresen hozzáadva!'); // Erfolgsmeldung
            this.fetchData(); // Aktualisiere die Daten nach dem Einfügen der neuen Zeile
          } else {
        //    ////console.error('Hiba történt:', response.data.message);
            alert('Hiba történt az új sor hozzáadása közben.');
          }
        } catch (error) {
      //    ////console.error('Hiba az API kérés során:', error);
          alert('API-hiba történt az új sor hozzáadása közben.');
        }
      },

      async deleteRow(row) {//**************************************************************************************** */
            try {
              // Überprüfen, ob die Bedingungen erfüllt sind

////console.log('STORNO -- row.az',row.az);
////console.log('row.tipus1',row.tipus1);
////console.log('row.tipus2',row.tipus2);
////console.log('napimellek_az1',row.napimellek_az1);
////console.log('napimellek_az2',row.napimellek_az2);


              //if (x===1) {
          if ((row.tipus1 == 5 || row.tipus1 == 7) && (row.tipus2 == 5 || row.tipus2 == 7) &&  row.napimellek_az1 == 0 && row.napimellek_az2 == 0) {              

                const postData = {
                  az: row.az // Übergebe die ID der Zeile, die gelöscht werden soll
                };
                const response = await axiosInstance.post('/drive/del_row.php', postData);

                if (response.status === 200 && response.data.success) {
           //       alert('A sor sikeresen törölve.');
                  this.fetchData(); // Aktualisiere die Daten nach dem Löschen der Zeile
                } else {
                  ////console.error('Hiba történt:', response.data.message);
                  alert('Hiba történt a sor törlése közben.');
                }
              } else {
                alert('Csak az üres sorok törölhetőek.');
              }
            } catch (error) {
              ////console.error('Hiba történt az API kérés során:', error);
              alert('API-hiba történt a sor törlése közben.');
            }
          },

   

    previousDay() {//******************************************************************* */
      const napiDatum = this.getNapiDatum ? new Date(this.getNapiDatum) : new Date();
    napiDatum.setDate(napiDatum.getDate() - 1);
    this.$store.commit('setNapiDatum', napiDatum.toISOString().split('T')[0]); // Aktualisiere napi_datum im Store
    this.fetchData(); 
    },

    nextDay() {//****************************************************************************** */
      const napiDatum = this.getNapiDatum ? new Date(this.getNapiDatum) : new Date();
    napiDatum.setDate(napiDatum.getDate() + 1);
    this.$store.commit('setNapiDatum', napiDatum.toISOString().split('T')[0]); // Aktualisiere napi_datum im Store
    this.fetchData(); 
    },
    async fetchData() {  //******************************************************************************** */
      ////console.log('Start axios');

      try {

        const napiDatum = this.getNapiDatum || this.formattedDate; // Verwende napiDatum oder das aktuelle Datum
        console.log('fetchData für napiDatum:', napiDatum);

      const postData = {
          cegaz: this.ceg_az, // Aktueller Firmen-ID (m_cegaz)
          datum: this.getNapiDatum // Aktuelles Datum im richtigen Format
        };

        const response = await axiosInstance.post('/drive/list_drive.php', postData);

     //     this.valasz = response.data;

             // Sicherstellen, dass die API die Daten korrekt zurückgibt
        if (response.data && response.data.data) {
          ////console.log('Daten erfolgreich abgerufen:', response.data.data);

          // Verarbeiten der Daten, um sie in den Tabellen anzuzeigen
          this.fuvarszervezok.forEach((fuvarszervezo) => {
            fuvarszervezo.data = response.data.data.filter(
              (item) => item.fsz === fuvarszervezo.name
            );
          });
        } else {
          ////console.log('Fehler beim Abrufen der Daten.');
          alert("Hiba történt az adatok lekérdezése során, értesítse a programozót.");
        }
      } catch (error) {
        ////console.error('Fehler beim Abrufen der Daten:', error);
        alert("Hiba történt az adatok lekérdezése során, értesítse a programozót.");
      }

      ////console.log('Ende');
      ////console.log(this.valasz);
    },
    showDetails(row, event) {  //************************************************************ */
      // Tooltip nur anzeigen, wenn tipus1 und tipus2 beide 0 sind
      if (row.tipus1 === '0' && row.tipus2 === '0') {
        this.tooltipData = {
          felrako:row.szoveg1,
          lerako: row.szoveg2,
          megrendelo: row.megrendelo,
          cikk: row.cikk,
          szall_hatarido: row.szall_hatarido,
          hivatkozasi_szam: row.hivatkozasi_szam,
          megr_ar: row.m_egysegar,
          megrar_me:row.m_egysegarme,
          alv_ar: row.mar_egysegar,
          alvar_me:row.mar_egysegarme,
          megjegyzes:row.megjegyzes
        };
        this.updateTooltipPosition(event);
        document.addEventListener('mousemove', this.updateTooltipPosition);
      }
    },
    handleCellClick(row, side) { //*********************************************************************** */
      // Überprüfen, ob die Bedingungen für tipus1 und tipus2 erfüllt sind
      if ((side === 1 && row.tipus1 ==0 ) || (side === 2 && row.tipus2 == 0)) {
      // Erstelle ein Objekt mit den relevanten Daten
            const orderData = {
              az: row.az,
              cegaz: row.cegaz,
              datum: row.datum,
              viszonylat: row.viszonylat,
              napifo_az: row.napifo_az,
              napimellek_az: side === 1 ? row.napimellek_az1 : row.napimellek_az2,
              tipus: side === 1 ? row.tipus1 : row.tipus2,
              megrendelo: row.megrendelo,
              cikk: row.cikk,
              szall_hatarido: row.szall_hatarido,
              hivatkozasi_szam: row.hivatkozasi_szam,
              oldal: side,
              csoport:row.csoport,
              fsz:row.fsz,
              gmp:row.gmp
            };

            // Speichere die Daten im Store
            this.setorderData(orderData);

      // Navigiere zur neuen OrderDetails-Seite
      this.$router.push({ name: 'orderDetails' });

      } else if ((side === 1 && (row.tipus1 == 5 || row.tipus1 == 51)) || (side === 2 && (row.tipus2 == 5 || row.tipus2 == 51 ))) {
        // Erstelle ein Objekt mit den relevanten Daten
        const orderData = {
          az: row.az,
          cegaz: row.cegaz,
          datum: row.datum,
          viszonylat: row.viszonylat, // Unterschied je nach Seite
          napifo_az: row.napifo_az,
          napimellek_az: side === 1 ? row.napimellek_az1 : row.napimellek_az2,
          tipus: side === 1 ? row.tipus1 : row.tipus2,
          megrendelo: row.megrendelo,
          cikk: row.cikk,
          szall_hatarido: row.szall_hatarido,
          hivatkozasi_szam: row.hivatkozasi_szam,
          oldal: side,
          fo_megjegyzes:row.megjegyzes,
          csoport: row.csoport,
          fsz:row.fsz,
          gmp:row.gmp
        };

        // Speichere die Daten im Store
        this.setorderData(orderData);

        // Leere die Truck-Daten im Store
        this.$store.commit('clearTruckData');

        // Navigiere zur newTruck Seite
        this.$router.push({ name: 'newTruck' });


      }else  if ((side === 1 && (row.tipus1 == 6 || row.tipus1 == 61)) || (side === 2 && (row.tipus2 == 6 || row.tipus2 == 61))) {
      
          // Lade die Truck-Daten basierend auf `ceg_az` und `napimellek_az`
          const napimellekAz = side === 1 ? row.napimellek_az1 : row.napimellek_az2;
          const cegaz = row.cegaz;

          ////console.log('cegaz', cegaz);
          ////console.log('napimellek_az', napimellekAz);

          // Wenn `napimellekAz` vorhanden ist, bedeutet es, dass es eine existierende Lieferung ist
          if (napimellekAz) {
            this.loadTruckData(cegaz, napimellekAz, true); // true für Bearbeitungsmodus
          } else {
            this.$router.push({ name: 'newTruck' }); // Leite zur neuen Lieferung weiter
          }
      
     } else {
        // Bedingung nicht erfüllt, optional: Zeige eine Nachricht oder mache nichts
        ////console.log('Bedingung nicht erfüllt: tipus1 oder tipus2 ist nicht 5');
      }
    },

    async loadTruckData(cegaz, napimellekAz) {//*************************************************************************************** */
      try {
        const postData = {
          ceg_az: cegaz,
          napimellek_az: napimellekAz
        };

        // API-Aufruf zum Laden der Truck-Daten mit `ceg_az` und `napimellek_az`
        const response = await axiosInstance.post('/napi_mellek/list_truck.php', postData);

        // Überprüfen, ob die Antwort erfolgreich ist und Daten vorhanden sind
        if (response.data && response.data.data) {
          ////console.log('Truck-Daten empfangen:', response.data.data[0]);

          // Committe die geladenen Truck-Daten in den Vuex-Store
          this.$store.commit('setTruckData', response.data.data[0]);

          // Navigiere zur entsprechenden Seite: `editTruck` für Bearbeitung oder `newTruck` für neue Lieferungen
          const targetPage =  'editTruck';
          this.$router.push({ name: targetPage });
        } else {
          ////console.error('Fehler beim Laden der Truck-Daten.');
        }
      } catch (error) {
        ////console.error('Fehler beim API-Aufruf:', error);
      }
    },


    showContextMenu(row, event, side) {
      // Speichere die Position des Kontextmenüs
      this.menuX = event.clientX;
      this.menuY = event.clientY;
      
      // Speichere die Zeile und die Seite (1 für tipus1, 2 für tipus2)
      this.selectedRow = row;
      this.selectedSide = side;
      
      // Zeige das Menü an
      this.showMenu = true;
      
      // Verstecke das Menü, wenn irgendwo anders geklickt wird
      document.addEventListener('click', this.closeContextMenu);
    },
    closeContextMenu() {
      this.showMenu = false;
      document.removeEventListener('click', this.closeContextMenu);
    },

    async updateTipus(row, side) { //********************************************************************************** */
    try {
        // Bestimme den aktuellen Tipus für die ausgewählte Seite (1 oder 2)
        const currentTipus = side === 1 ? row.tipus1 : row.tipus2;

        // Bestimme den neuen Tipus-Wert basierend auf den aktuellen Werten
        let newTipus = null;
        
        if (currentTipus === '5') {
            newTipus = '51';
        } else if (currentTipus === '51') {
            newTipus = '5';
        } else if (currentTipus === '6') {
            newTipus = '61';
        } else if (currentTipus === '61') {
            newTipus = '6';
        } else if (currentTipus === '7') {
            newTipus = '71';
        } else if (currentTipus === '71') {
            newTipus = '7';
        } else {
            // Wenn der Wert nicht zu den erwarteten gehört, abbrechen
            ////console.log('Nur Zellen mit tipus=5, 6, 7, 51, 61, oder 71 können geändert werden.');
            alert('Csak a priorizált rakodások módosíthatóak.');
            return;
        }

        const postData = {
            az: row.az, // Verwende die ID, um den richtigen Datensatz zu identifizieren
            tipusField: side === 1 ? 'tipus1' : 'tipus2', // Bestimme, welches Feld aktualisiert werden soll
            tipusValue: newTipus // Setze den neuen Tipus-Wert
        };

        const response = await axiosInstance.post('/drive/drive_chgtipus.php', postData);

        if (response.status === 200 && response.data.success) {
            this.fetchData(); 
            ////console.log('Tipus erfolgreich aktualisiert in der Datenbank');
        } else {
            ////console.error('Fehler beim Aktualisieren des Tipus:', response.data.message);
            alert('Hiba történt az adatbázis frissítése közben.');
        }
    } catch (error) {
        ////console.error('Fehler bei der Anfrage zur Aktualisierung des Tipus:', error);
        alert('Hiba történt az adatbázis frissítése közben.');
    }
},
async updateTipusTo63(row, side) {
  // Ladezustand setzen   ha le van jelentve, akkor tipus=63, ha nem, akkor törli a
  this.loading = true;

  try {
    // Dynamischer Feldname für die Seite
    const tipusField = side === 1 ? 'tipus1' : 'tipus2';

    // Überprüfe den aktuellen Wert von tipus1 oder tipus2
    const currentValue = row[tipusField];
    let postData;

    if (currentValue === '62') {
      // Original-Datenstruktur beibehalten
      postData = {
        az: row.az, // Identifizierende ID
        tipusField: side === 1 ? 'tipus1' : 'tipus2',
        tipusValue: '63' // Setze den tipus-Wert auf 63
      };
    } else if (currentValue === '6' || currentValue === '61') {
      // Neue Struktur verwenden
      postData = {
        az: row.az, // Identifizierende ID
        tipusField: side === 1 ? 'szoveg1' : 'szoveg2', // Bestimme neues Feld
        tipusValue: '...' // Setze neuen Wert
      };
    } else {
      ////console.warn('Unbekannter tipus-Wert:', currentValue);
      alert('Hiba a törlés során vagy nem volt rendszám megadva.');
      return;
    }

    // Anfrage senden

    ////console.log('chgtipus',postData);
    const response = await axiosInstance.post('/drive/drive_chgtipus.php', postData);

    if (response.status === 200 && response.data.success) {
   
      // Lokale Aktualisierung der Tabelle
      /*      
      if (currentValue === '62') {
        row[tipusField] = '63';
      } else {
        row[side === 1 ? 'szoveg1' : 'szoveg2'] = '...';
      }
      */

      // Aktualisierte Daten erneut abrufen
      await this.fetchData();

      ////console.log('Tipus erfolgreich aktualisiert in der Datenbank');
    } else {
      ////console.error('Fehler beim Aktualisieren des Tipus:', response.data.message);
      alert('Hiba történt az adatbázis frissítése közben.');
    }
  } catch (error) {
    ////console.error('Fehler bei der Anfrage zur Aktualisierung des Tipus:', error);
    alert('Hiba történt az adatbázis frissítése közben.');
  } finally {
    // Ladezustand zurücksetzen
    this.loading = false;
  }
}
,

  async updateTipusTo62(row, side) {//****************************************************************************** */
    try {
      // tipus62=lejelentve
      // Setze den tipus auf 62 für die ausgewählte Seite (1 oder 2)
      const postData = {
        az: row.az, // Verwende die ID, um den richtigen Datensatz zu identifizieren
        tipusField: side === 1 ? 'tipus1' : 'tipus2', // Bestimme, welches Feld aktualisiert werden soll
        tipusValue: '62' // Setze den tipus-Wert auf 63
      };

      const response = await axiosInstance.post('/drive/drive_chgtipus.php', postData);

      if (response.status === 200 && response.data.success) {
        this.fetchData(); 
        ////console.log('Tipus erfolgreich auf 63 aktualisiert in der Datenbank');
      } else {
        ////console.error('Fehler beim Aktualisieren des Tipus:', response.data.message);
        alert('Hiba történt az adatbázis frissítése közben.');
      }
    } catch (error) {
      ////console.error('Fehler bei der Anfrage zur Aktualisierung des Tipus:', error);
      alert('Hiba történt az adatbázis frissítése közben.');
    }
  },


    clearDetails() {//******************************************************************************** */
      ////console.log("Mouseleave, Tooltip wird entfernt");
      this.tooltipData = null;
      document.removeEventListener('mousemove', this.updateTooltipPosition);
    },
    updateTooltipPosition(event) {//******************************************************************* */
      this.tooltipX = event.clientX + 10;
      this.tooltipY = event.clientY + 10;
    //  ////console.log(`Tooltip Position: X=${this.tooltipX}, Y=${this.tooltipY}`);
    },


    
    async fetchFuvarszervezokFromAPI() { //*********************************************************  */
      try {

        ////console.log('fuvarszervezok lekérdezése');
        const postData = {
          cegaz: this.currentCegaz, // Aktueller Firmen-ID (m_cegaz)
          datum: this.getNapiDatum, // Aktuelles Datum im richtigen Format
          fsz:this.fsz
        };

        ////console.log('postData=',postData);
        const response = await axiosInstance.post('/drive/list_fsz.php',postData);

        ////console.log('fsz lista:'+response.data.data);

        this.fuvarszervezok = response.data.data.map((item, index) => ({
          name: item.fsz,
          color: this.getColorForIndex(index),
          data: []
        }));

        ////console.log('fuvarszervezok this:',this.fuvarszervezok);

      } catch (error) {
        ////console.error('Fehler beim Laden der Fuvarszervezok von der API:', error);
        this.fuvarszervezok = [{ name: 'Standard Fuvarszervezok', color: '#FFCDD2', data: [] }];
      }
    },
    getColorForIndex(index) {//**************************************************************************************** */
      const colors = ['#FFCDD2', '#F8BBD0', '#E1BEE7', '#D1C4E9', '#C5CAE9', '#BBDEFB', '#B3E5FC', '#B2EBF2', '#B2DFDB'];
      return colors[index % colors.length];
    },
    formatDate(date) {  //************************************************************************************************************ */
      const daysOfWeek = ["vasárnap", "hétfő", "kedd", "szerda", "csütörtök", "péntek", "szombat"];
      const dayName = daysOfWeek[date.getDay()]; // Wochentag auf Ungarisch
      const formattedDate = date.toLocaleDateString(); // Formatiertes Datum
      return `${formattedDate} (${dayName})`; // Datum und Wochentag zusammen
    },
    isSelectedDate(date) {//********************************************************************************************************** */
      const napiDatum = this.getNapiDatum ? new Date(this.getNapiDatum) : new Date();
    return (
      napiDatum.getDate() === date.getDate() &&
      napiDatum.getMonth() === date.getMonth() &&
      napiDatum.getFullYear() === date.getFullYear()
    );
    },
    selectDate(date) {//************************************************************************************************************* */
      const formattedDate = date.toISOString().split('T')[0]; // Konvertiere das Datum in das Format "YYYY-MM-DD"
      this.$store.commit('setNapiDatum', formattedDate);
      
      this.$nextTick(() => {
        if (this.fsz) {
          this.initializeFuvarszervezok();
          console.log('selectDate - fetchdate',this.getNapiDatum)
          this.fetchData();
        }
      });

      
    },
    initializeFixedDates() {//****************************************************************************************************** */
      const baseDate = new Date();
      baseDate.setDate(baseDate.getDate() - 2);
   //   this.handleDateChange(this.formatDate(this.currentDate));
      for (let i = 0; i < 7; i++) {
        const date = new Date(baseDate);
        this.fixedDates.push(date);
        baseDate.setDate(baseDate.getDate() + 1);
      }
    },
    getBackgroundColor(tipus) {//************************************************************************************************** */
      switch (parseInt(tipus, 10)) {  // Hier wandeln wir den String in einen Integer um
      case 0:
        return '#55b3ff';  // világos kék  viszonylat
      case 1:
        return '#e6f2ff';  // világosabb kék  tonna
      case 2:
        return '#009900';  // zöld 
      case 3:
        return '#ff0000';   //piros
      case 51:
        return '#ffff00';   //citrom sárga
      case 61:
        return '#ffff00';   //citrom sárga
      case 10:
        return '#009933';  //zöld
        case 62:
        return '#009933';  //zöld        
        case 63:
        return '#009933';  //zöld        
      case 20:
        return '#ffc2b3';  //halvány piros
      default:
        return '#ffffff'; // Standardfarbe, falls kein gültiger tipus-Wert vorliegt
    }
  },

  getFontColor(tipus) {//********************************************************************************************************* */
  switch (parseInt(tipus, 10)) {  // Wandeln des Strings in einen Integer
    case 63:
      return '#ff0000';  // rot für tipus 63
    default:
      return '#000000'; // schwarz als Standard-Schriftfarbe
  }
},

    initializeFuvarszervezok() {//********************************************************************************************** */

      const currentFsz = this.fsz || 'Standard Fuvarszervezok';
      ////console.log("fsz=", currentFsz);


      this.fetchFuvarszervezokFromAPI().then(() => {
        // Überprüfe, ob der Benutzer ein Fuvarszervező ist, bevor der currentFsz hinzugefügt wird
        if (this.isFuvarszervezo && !this.fuvarszervezok.some(fsz => fsz.name === currentFsz)) {
          this.fuvarszervezok.unshift({
            name: currentFsz,
            color: '#FFCDD2',
            data: []
          });
        }
      });
    },
  },
  mounted() {
  // Setze die festen Datumswerte für die Buttons
  this.initializeFixedDates();
  
  // Log-Parameter initialisieren
  this.updateLogParams();
  
  // Prüfen, ob napiDatum bereits gesetzt ist
  if (!this.getNapiDatum) {
    // Setze napiDatum auf das heutige Datum, wenn es nicht definiert ist
    const today = new Date();
    const formattedToday = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
    this.$store.commit('setNapiDatum', formattedToday);
    console.log('napiDatum war nicht gesetzt, neues napiDatum:', formattedToday);
  } else {
    console.log('Vorhandenes napiDatum:', this.getNapiDatum);
  }

  // Daten initialisieren und laden
  this.$nextTick(() => {
    if (this.fsz) {
      this.initializeFuvarszervezok(); // Lade Fuvarszervezok
    }
    this.fetchData(); // Daten für das aktuelle napiDatum laden
  });
}

};
</script>

<style scoped>


.modal {
  position: fixed;
 
  transform: translate(-50%, -50%);
  background-color: white; /* Hintergrundfarbe sichtbar */
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 2000; /* Sicherstellen, dass es über anderen Inhalten liegt */
  width: 400px; /* Feste Breite */
  height: 300px; /* Feste Höhe */
  border: 2px solid red; /* Sichtbarer Rand zum Testen */
}
.modal-content {
  display: flex;
  flex-direction: column;
}

button {
  margin-top: 10px;
}

.table-responsive {
  margin-bottom: 1rem;
  margin-right: 1px !important;
}

.fuvarszervezo-name {
  font-weight: bold;
  margin-bottom: 10px;
  padding: 5px;
  color: black; /* Schriftfarbe auf schwarz gesetzt */
}

.row.flex-nowrap {
    display: flex;
    justify-content: flex-start; /* Tabellen linksbündig ausrichten */
    gap: 10px; /* Abstand zwischen den Tabellen */
    overflow-x: auto; /* Optional: Falls mehr Tabellen kommen, Scrollbalken erlauben */
}


.col-2 {
  min-width: auto; /* Lässt die Breite dynamisch sein, abhängig vom Inhalt und verfügbaren Platz */
}

table {
  table-layout: fixed;
  width: 100%;
  margin-right: 1px; /* Reduziert den rechten Margin der Tabelle */
}


td {
  box-sizing: border-box;
  padding: 4px; /* Reduziertes Padding, angepasst nach Bedarf */
  font-size: 0.8rem; /* Kleinere Schriftgröße */
  line-height: 0.6; /* Reduzierter Zeilenabstand */
  white-space: nowrap; /* Verhindert Zeilenumbruch */
  overflow: hidden; /* Optional: Verhindert das Überlaufen von Text */
  text-overflow: ellipsis; /* Optional: Zeigt "..." an, wenn der Text zu lang ist */
}

.tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Erhöhe diesen Wert, falls nötig */
  visibility: visible; /* Stelle sicher, dass der Tooltip sichtbar ist */
  opacity: 1; /* Eventuell opacity auf 1 setzen, falls es auf 0 ist */
}

.tooltip p {
  margin: 0; /* Entferne den Standard-Außenabstand */
  line-height: 1.2; /* Setze einen kleineren Zeilenabstand, z. B. 1.2 */
}

div.row.flex-nowrap {
  padding-right: 2px; /* Reduziert den Padding auf der rechten Seite */
}


.context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.context-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.context-menu li {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu li:hover {
  background-color: #eee;
}




</style>
